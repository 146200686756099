import * as React from 'react';
import { graphql } from 'gatsby';
import styled from '@emotion/styled';
import App from '../components/layout/App';
import Link from '../components/base/Link';
import {
  color,
  fontWeight,
  mqUp,
  mqDown,
  header,
  breakpoint,
} from '../constants/styles';

const Section = styled.div`
  padding: calc(6rem + ${header.heightSmall}) 4rem 4rem;
  ${mqUp(breakpoint.mainNav)} {
    padding: calc(8rem + ${header.heightBase}) 12rem 4rem;
  }
  ${mqUp(breakpoint.desktopLarge)} {
    padding: calc(10rem + ${header.heightBase}) 20rem 4rem;
  }
`;

const Grid = styled.div`
  ${mqUp(breakpoint.mainNav)} {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, auto);
    grid-column-gap: 10rem;
    grid-row-gap: 10rem;
  }
  ${mqUp(breakpoint.desktop)} {
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(3, auto);
  }
  ${mqUp(breakpoint.desktopLarge)} {
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(4, auto);
    grid-column-gap: 12rem;
    grid-row-gap: 12rem;
  }
`;

const ProjectTitle = styled.h2`
  color: ${color.neutral};
  font-weight: ${fontWeight.medium};
  ${mqDown(breakpoint.mainNav)} {
    margin-top: 2rem;
  }
  ${mqUp(breakpoint.mainNav)} {
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    transform: translateY(-50%);
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
  }
`;

const Project = styled.article`
  position: relative;
  a {
    flex-grow: 1;
    display: block;
    text-align: center;
    text-decoration: none;
    &:hover {
      img {
        opacity: 0.15;
      }
      ${ProjectTitle} {
        opacity: 1;
      }
    }
  }
  img {
    display: block;
    width: 100%;
    margin: auto;
    transition: all 0.3s ease-in-out;
  }
  ${mqDown(breakpoint.mainNav)} {
    &:first-child {
      margin-top: 3rem;
    }
    &:not(:first-child) {
      margin-top: 6rem;
    }
  }
`;

interface WorksProps {
  rest: any;
  data: {
    content: {
      edges: [
        {
          node: {
            uid: string;
            data: {
              seo_title: string;
              title: string;
              description: {
                html: string;
              };
              cover: {
                alt: string;
                url: string;
              };
            };
          };
        },
      ];
    };
  };
}

const Works: React.SFC<WorksProps> = ({ data, ...rest }) => {
  const DATA = data.content.edges;

  return (
    <App {...rest}>
      <Section>
        <Grid>
          {DATA.map((slide, index) => (
            <Project key={`slide-${index}`}>
              <Link to={`/realisations/${slide.node.uid}`}>
                <img
                  src={slide.node.data.cover.url}
                  alt={slide.node.data.cover.alt}
                />
                <ProjectTitle>{slide.node.data.title}</ProjectTitle>
              </Link>
            </Project>
          ))}
        </Grid>
      </Section>
    </App>
  );
};
export default Works;

export const WorksPage = graphql`
  query WorksPageQuery {
    content: allPrismicProject(
      sort: { order: DESC, fields: last_publication_date }
    ) {
      edges {
        node {
          uid
          data {
            title
            cover {
              alt
              url
            }
            description {
              html
            }
          }
        }
      }
    }
  }
`;
